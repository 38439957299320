import styled from 'styled-components'
import { media } from '../../theme/'
export const Wrapper = styled.div`
  position: fixed;
  top: -50px;
  left: -50px;
  right: -50px;
  bottom: -50px;
  z-index:0;
  ${media.LargeScreenUp`
    z-index: -1;
  `};
`
