// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-news-listing-page-js": () => import("/opt/build/repo/src/templates/news-listing-page.js" /* webpackChunkName: "component---src-templates-news-listing-page-js" */),
  "component---src-templates-release-details-page-js": () => import("/opt/build/repo/src/templates/release-details-page.js" /* webpackChunkName: "component---src-templates-release-details-page-js" */),
  "component---src-templates-artist-details-page-js": () => import("/opt/build/repo/src/templates/artist-details-page.js" /* webpackChunkName: "component---src-templates-artist-details-page-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-merch-listing-page-js": () => import("/opt/build/repo/src/templates/merch-listing-page.js" /* webpackChunkName: "component---src-templates-merch-listing-page-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/build/repo/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-artist-listing-page-js": () => import("/opt/build/repo/src/templates/artist-listing-page.js" /* webpackChunkName: "component---src-templates-artist-listing-page-js" */),
  "component---src-templates-release-listing-page-js": () => import("/opt/build/repo/src/templates/release-listing-page.js" /* webpackChunkName: "component---src-templates-release-listing-page-js" */),
  "component---src-templates-home-page-js": () => import("/opt/build/repo/src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

