export function credits () {
  console.log('%c Credits: Dan Nisbet -- Design & Development -- @thedistricts, dan@weprevail.co.uk', 'padding: 5px; background:#BF1A18; line-height:25px;color: white')
}

export const isWindowAvailable = (typeof window !== 'undefined')

export function postToURL (values, url, isRedirected) {
  values = values || {}
  var form = document.createElement('form')
  form.setAttribute('action', url)
  form.setAttribute('method', 'POST')
  form.setAttribute('style', 'display: none')
  form.setAttribute('content-type', 'application/x-www-form-urlencoded')
  for (var property in values) {
    if (values.hasOwnProperty(property)) {
      var value = values[property]
      var input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', property)
      input.setAttribute('value', value)
      form.appendChild(input)
    }
  }
  if (!isRedirected) {
    var fallframe = document.createElement('iframe')
    fallframe.setAttribute('name', 'fallframe')
    fallframe.setAttribute('id', 'fallframe')
    fallframe.setAttribute('width', 0)
    fallframe.setAttribute('height', 0)
    fallframe.setAttribute('border', 0)
    form.setAttribute('target', 'fallframe')
    document.body.appendChild(fallframe)
  }
  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

export function stripTrailingSlash (str) {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1)
  }
  return str
}
