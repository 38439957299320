/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import ViewportBackground from './src/components/ViewportBackground'
import { LargeToGaintScreenUp, SmallToMediumScreenOnly } from './src/theme'
import { first } from 'lodash'

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const currentPage = props.data ? props.data.wordpressPage : null
  let assetTexture = null
  let colormode = false
  let bgColor = '#262626'
  let smallScreenBg = bgColor

  if (currentPage && currentPage.acf.featured_release) {
    const details = first(currentPage.acf.featured_release)
    const light = `https://res.cloudinary.com/prevail/image/fetch/w_1440,h_950,c_fill/e_saturation:-70/e_brightness:90/e_blur:2000/c_fill,w_1440,h_950,o_75,l_overlay-texture-gradient-white/e_multiply,c_fill,w_1440,h_950,o_45,l_overlay-texture-grunge/${details.acf.release_artwork.source_url}`
    const dark = `https://res.cloudinary.com/prevail/image/fetch/w_1440,h_950,c_fill,e_blur:2000/e_multiply,c_fill,w_1440,h_950,l_overlay-texture-grunge/e_multiply,c_fill,w_1440,h_950,o_60,l_overlay-texture-gradient/${details.acf.release_artwork.source_url}`

    colormode = !currentPage.acf.featured_background
    assetTexture = (colormode) ? dark : light
    bgColor = (colormode) ? bgColor : '#F3F3F3'
    smallScreenBg = `${bgColor} url(https://res.cloudinary.com/prevail/image/fetch/w_600,h_1400,c_fill/e_saturation:-70/e_brightness:90/e_blur:2000/c_fill,w_600,h_1400,o_75,l_overlay-texture-gradient-white/e_multiply,c_fill,w_600,h_1400,o_45,l_overlay-texture-grunge/${details.acf.release_artwork.source_url})`
  }

  return <>
    {element}
    <SmallToMediumScreenOnly>
      <div style={{ position: 'fixed', top: 0, height: '100vh', width: '100vw', background: smallScreenBg }} />
    </SmallToMediumScreenOnly>
    <LargeToGaintScreenUp>
      <div style={{ position: 'fixed', top: 0, height: '100vh', width: '100vw', background: bgColor }} />
      <ViewportBackground texture={assetTexture} colormode={colormode} />
    </LargeToGaintScreenUp>
    </>
}
