import React, { Component } from 'react'
import * as PIXI from 'pixi.js'
import { clone } from 'lodash'
import { isWindowAvailable } from '../../util'
import { Wrapper } from './styles'
import assetTexture from '../../images/temp-content-bg.svg'
import assetDisplacementTexture from '../../images/dmaps/512x512/clouds.jpg'

const sitetitle = 'little\nrocket\nrecords'
const padding = 20

class ViewportBackground extends Component {
  constructor (props) {
    super(props)
    if (isWindowAvailable) {
      this.app = undefined
      this.canvas = undefined
      this.background = undefined
      this.textStyle = undefined
      this.displacementSprite = undefined
      this.displacementFilter = undefined
      // load our assets
      if (!PIXI.loader.resources['defaultbackground']) {
        PIXI.loader.add('defaultbackground', assetTexture)
      }
      if (!PIXI.loader.resources['background'] && this.props.texture) {
        PIXI.loader.add('background', this.props.texture)
      }
      if (!PIXI.loader.resources['displacementTexture']) {
        PIXI.loader.add('displacementTexture', assetDisplacementTexture)
      }
      this.state = { customTexture: props.texture }
    }
  }

  /**
   * After mounting, add the Pixi Renderer to the div and start the Application.
   */
  componentDidMount () {
    if (isWindowAvailable) {
      this.app = new PIXI.Application({
        autoResize: true,
        resolution: window.devicePixelRatio,
        transparent: true
      })
      this.canvas.appendChild(this.app.view)
      PIXI.loader
        .load((loader, resources) => {
          // Background
          const texture = this.props.texture ? resources.background : resources.defaultbackground
          const background = new PIXI.Sprite(texture.texture)
          background.x = 0
          background.y = 0
          background.width = this.app.screen.width
          background.height = this.app.screen.height

          // Displacement
          this.displacementSprite = new PIXI.Sprite(resources.displacementTexture.texture)
          this.displacementFilter = new PIXI.filters.DisplacementFilter(this.displacementSprite)
          this.displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT
          this.displacementSprite.scale.x = 2
          this.displacementSprite.scale.y = 2
          this.displacementSprite.anchor.set(0.5)
          this.displacementSprite.x = this.app.renderer.width / 2
          this.displacementSprite.y = this.app.renderer.height / 2

          // PIXI tries to fit the filter bounding box to the renderer so we optionally bypass
          this.displacementFilter.autoFit = true
          this.app.stage.addChild(this.displacementSprite)
          // Text
          const llrText1 = new PIXI.Text(sitetitle)
          const llrText2 = new PIXI.Text(sitetitle)
          this.background = this.app.stage.addChild(background)
          this.llrText1 = this.background.addChild(llrText1)
          this.llrText2 = this.background.addChild(llrText2)
          this.setTextStyle()
          this.llrText1.org = { w: this.llrText1.width, h: this.llrText1.height, diff: window.innerHeight - this.llrText1.height }

          this.resize()
          this.app.ticker.add(this.ticker)
          this.app.stage.filters = [this.displacementFilter]
          this.app.start()
          window.addEventListener('resize', this.resize)
        })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.texture !== prevProps.texture && isWindowAvailable) {
      PIXI.loader
        .load((loader, resources) => {
          const texture = this.props.texture ? resources.background : resources.defaultbackground
          if (texture) this.background.texture = texture.texture
          this.setTextStyle()
        })
    }
  }

  ticker = (delta) => {
    this.displacementSprite.x += 0.14 * (delta / 35)
    this.displacementSprite.y += 2.24 * (delta / 35)
    this.displacementSprite.rotation += (0.1 / 35)
  }

  setTextStyle = () => {
    if (this.props.texture) {
      this.llrText1.blendMode = PIXI.BLEND_MODES.SOFT_LIGHT

      this.llrText2.blendMode = PIXI.BLEND_MODES.ADD

      this.llrText1.x = 5 + (padding * 4)
      if (!this.props.colormode) {
        this.llrText1.alpha = 0.08
        this.llrText2.alpha = 0.1
      } else {
        this.llrText1.alpha = 0.2
        this.llrText2.alpha = 0.38
      }
    } else {
      this.llrText1.alpha = 0.15
      this.llrText2.alpha = 0.15
      this.llrText1.x = (padding * 4)
    }

    this.llrText2.x = (padding * 4)
    this.llrText2.y = this.llrText1.y = (padding * 6.5)
    // force it to re-render
    this.textStyle = {
      fontFamily: 'Misproject',
      fontSize: 360,
      lineHeight: 255,
      letterSpacing: 0.1,
      padding: 550,
      fill: '#000'
    }
    if (this.props.texture) {
      this.textStyle.fill = '#140100'
    }
    this.llrText1.style = clone(this.textStyle)
    this.llrText2.style = clone(this.textStyle)
  }

  // Resize function window
 resize = () => {
   this.app.renderer.resize(window.innerWidth, window.innerHeight)
   this.background.y = -padding
   this.background.x = -padding
   this.background.width = window.innerWidth + (padding * 2)
   this.background.height = window.innerHeight + (padding * 2)
   this.setTextStyle()
   this.llrText1.height = this.llrText2.height = window.innerHeight - this.llrText1.y + (padding * 4)
   this.llrText1.width = this.llrText2.width = (window.innerHeight - this.llrText1.org.diff - this.llrText1.y) * (this.llrText1.org.w / this.llrText1.org.h) + (padding * 4)
 }

 /**
   * Simply render the div that will contain the Pixi Renderer.
   */
 render () {
   if (!isWindowAvailable) return null
   return (<div
     style={{ position: 'fixed', top: 0, height: '100vh', width: '100vw', backfaceVisibility: 'hidden' }}
     ref={(thisDiv) => { this.canvas = thisDiv }}>
     <Wrapper texture={this.texture} />
   </div>
   )
 }
}

export default ViewportBackground
