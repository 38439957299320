import React from 'react'
import styled, { css } from 'styled-components'
import theme from 'styled-theming'
import { rem } from 'polished'
import Responsive from 'react-responsive'

export const blue = {
  darkest: 'rgba(34, 35, 66, 1)',
  dark: 'rgba(34, 35, 66, 0.75)'
}
export const red = {
  mid: 'rgba(191, 26, 24, 1)',
  dark: 'rgba(178, 18, 16, 1)'
}
export const grey = {
  darkest: 'rgba(38, 38, 38, 1)',
  dark: 'rgba(136, 166, 186, 1)',
  mid: 'rgba(226, 222, 221, 1)',
  light: 'rgba(151, 151, 151, 0.15)'
}
export const white = {
  mid: 'rgba(246, 246, 250, 1)',
  light: '#FFFFFF'
}
export const green = {
  mid: 'rgba(0, 203, 190, 1)'
}
export const yellow = {
  light: 'rgba(250, 244, 166, 1)'
}
export const black = {
  mid: 'rgba(38, 38, 38, 1)'
}

export const txtBodyColour = theme('mode', {
  dark: grey.darkest
})
export const txtBodyColourContrast = theme('mode', {
  dark: white.light
})
export const txtBodyColourMidContrast = theme('mode', {
  dark: grey.dark
})
export const borderColour = theme('mode', {
  dark: grey.light
})
export const borderHighlightColour = theme('mode', {
  dark: red.dark
})
export const txtHeadingColour = theme('mode', {
  dark: green.mid
})
export const formSubmitTxtColour = theme('mode', {
  dark: yellow.light
})
export const priceColour = theme('mode', {
  dark: yellow.light
})
export const darkestBgColour = theme('mode', {
  dark: grey.darkest
})
export const darkBgColour = theme('mode', {
  dark: black.mid
})
export const midBgColour = theme('mode', {
  dark: white.mid
})
export const lightBgColour = theme('mode', {
  dark: white.light
})
export const RouterRollOver = theme('mode', {
  dark: red.mid
})
export const MenuBg = theme('mode', {
  dark: red.mid
})
export const ctaButtonColor = theme('mode', {
  dark: red.mid
})

export const breakpoints = [
  600, 1000, 1350
]

export const overrides = {
  theme: {
    fonts: {
      sans: '"Lato", Helvetica, Arial, sans-serif',
      serif: '"Misproject", Georgia, Times, "Times New Roman", serif',
      distressed: '"Misproject", Georgia, Times, "Times New Roman", serif'
    },
    weights: {
      light: 200,
      normal: 400,
      medium: 500,
      bold: 600
    },
    fontSizes: [
      12, 14, 16, 18, 20, 24, 28, 30, 36, 48, 64, 72, 80, 96, 144
    ],
    breakpoints: breakpoints
  }
}

export const typeface = {
  sans: overrides.theme.fonts.sans,
  serif: overrides.theme.fonts.serif
}

const sizes = {
  GiantScreenUp: breakpoints[2],
  LargeScreenUp: breakpoints[1],
  MediumScreenUp: breakpoints[0]
}

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export const LargeScreenUp = styled.div`
  display: none;
  width: 100%;
  ${media.LargeScreenUp`
    display: block;
  `};
`

export const MediumScreenUp = styled.div`
  display: none;
  ${media.MediumScreenUp`
    display: block;
  `};
`
export const SmallToMediumScreenOnly = styled.div`
  display: block;
  ${media.LargeScreenUp`
    display: none;
  `};
`

export const LargeToGaintScreenUp = ({ children }) => (
  <Responsive minWidth={breakpoints[1] + 1} children={children} />
)
// export const MediumScreenUp = ({ children }) => (
//   <Responsive minWidth={breakpoints[0]} children={children} />
// )
export const MediumScreenOnly = ({ children }) => (
  <Responsive minWidth={breakpoints[0]} maxWidth={breakpoints[1]} children={children} />
)
export const SmallScreenOnly = ({ children }) => (
  <Responsive maxWidth={breakpoints[0]} children={children} />
)
// export const SmallToMediumScreenOnly = ({ children }) => (
//   <Responsive maxWidth={breakpoints[1]} children={children} />
// )
export const SmallToLargeScreenOnly = ({ children }) => (
  <Responsive maxWidth={breakpoints[2]} children={children} />
)

export const width = { max: 1440, xOffset: { large: '10.395010395%', mid: '6%' } }
export const easeInExpo = 'cubic-bezier(0.95, 0.05, 0.795, 0.035)'
export const easeOutExpo = 'cubic-bezier(0.19, 1, 0.22, 1)'
export const easeInOutExpo = 'cubic-bezier(1, 0, 0, 1)'

export const easeOutCirc = 'cubic-bezier(0.075, 0.82, 0.165, 1)'
export const easeInOutCirc = 'cubic-bezier(0.785, 0.135, 0.15, 0.86)'
export const easeInOutBack = 'cubic-bezier(0.175, 0.885, 0.32, 1.275)'
// ease_in_circ: 'cubic-bezier(0.6, 0.04, 0.98, 0.335)',
// ease_out_circ: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
// ease_in_out_circ: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
// ease_in_back: 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',
// ease_out_back: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
// ease_in_out_back: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)'

export const StyledLinks = `
  a {
      color: {props => props.invert ? 'black' : 'white'};
      float: left;
      text-transform: uppercase;
      text-decoration: none;
      white-space: nowrap;
      font-weight: bold;
      font-size: ${rem(overrides.theme.fontSizes[0])};
      margin-left: 18px;
      margin-right: 18px;
      letter-spacing: 0.6px;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
      display: block;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${red.mid};
        transform: translateY(500%);
        opacity: 0;
        transition: transform 0.8s ${easeOutExpo}, opacity 0.8s ${easeOutExpo};
      }
      &:hover, &.active {
        &:after {
          transform: translateY(50%);
          opacity: 1;
        }
      }
      &.active {
        cursor: default;
      }
    }
`
